import {Fragment} from "react"
import {nFormatter} from "../../../utils/NumberFormatter"
import React from "react"
import {Furigana} from "../../Furigana";

export const WordFragment = word => {
    const kanjiJSX = word.kanjisInVocab ? word.kanjisInVocab.map(k =>
        <Fragment key={k.kanji}><h5>{k.kanji}</h5>
            <p>
                {k.meanings
                    .filter(m => m.lang === "en")
                    .map(m => m.meaning)
                    .join(", ")}
                <br/>
                <b>Kun-Yomi:</b> {k.kunYomi.map(kun => kun.reading.original.toString() + " (" + nFormatter(kun.frequency, 1) + ")").join(", ")}<br/>
                <b>On-Yomi:</b> {k.onYomi.map(on => on.reading.original.toString() + " (" + nFormatter(on.frequency, 1) + ")").join(", ")}<br/>
            </p>
        </Fragment>) : ""
    return (
        <div className="row">
            <div className="col-md-3">
                <div className="container d-flex h-100">
                    <div className="row justify-content-center align-self-center align-items-center mx-auto">
                        <p>
                            <b className="h2"><Furigana text={word.word} readings={word.furigana}/></b>
                            <br/>
                            <b>Frequency</b>: {nFormatter(word.totalOcurrences, 1)}
                            <br/><b>Rank</b>: {nFormatter(word.rank, 1)}
                        </p>
                    </div>
                </div>
            </div>

            <div className="col-md-9">
                <div className="card-block">
                    <p className="card-title h4"> {word.translations ? word.translations.join(", ") : ""}</p>
                    <div className="card-text">{kanjiJSX}</div>
                </div>
            </div>
        </div>)
}