import React, {Component} from "react"
import {UserContext} from "../UserContext"
import {API} from "aws-amplify"
import {Link} from "react-router-dom"
import {GenericTable} from "../components/GenericTable";

export default class Words extends Component {

    static contextType = UserContext

    state = {
        search: "",
        knownWords: [],
        fullWords: [],
        allWords: this.context.allWords ? this.context.allWords : []
    }

    async componentDidMount() {
        document.title = "Words - Kanji App"
        if (!this.props.isAuthenticated) return
        // this.context.refreshUser()

        // this.setState({allWords: this.context.allWords ? this.context.allWords : []})
        //     .then(() => this.refreshAllWords()
        //         .then(ws => this.refreshFullWords()))

            this.refreshAllWords()
                .then(ws => this.refreshFullWords())
    }

    readUser = async () => {
        return API.get("documentsGateway", "user/read")
    }

    readFullWords = async (words) => {
        return API.get("documentsGateway", "word/readMany?words=" + words.join(","))
    }

    refreshFullWords = async () => {
        this.readUser().then(u => this.readFullWords(u.knownWords).then(r => this.setState({
            knownWords: u.knownWords,
            fullWords: r
        })))
    }

    readAllWords = async () => {
        return API.get("documentsGateway", "word/summary")
    }

    refreshAllWords = async () => {
        this.readAllWords().then(ws => this.setState({allWords: ws})).catch(e => console.log("error: " + e))
    }

    itemMatchesTerm = (item, term) => item.w.includes(term)
        || (item.m.includes(term))

    itemToRow = (item) => {
        return <tr key={item.w}>
            <th scope="row" style={{fontWeight: "normal"}}><Link to={"/words/" + item.w}>{item.w}</Link></th>
            <td>{item.m}</td>
        </tr>
    }

    render() {
        const awt = <GenericTable items={this.state.allWords}
                                  itemsPerPage={12}
                                  itemToRow={this.itemToRow}
                                  columnNames={["Word", "Meaning"]}
                                  itemMatchesTerm={this.itemMatchesTerm}/>
        return (
            <div className="KnownWords m-2" className="mx-auto p-2" style={{maxWidth: "1280px"}}>
                <h1>Words</h1>
                {/*{wt}*/}
                {awt}
            </div>
        )
    }
}

