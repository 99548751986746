const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "kanjiapp-dev-user-docs"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://9w1kiyebji.execute-api.us-east-1.amazonaws.com/dev/"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_tb4GD6dga",
    APP_CLIENT_ID: "4fmi0jn4rsftrgkqlsbd05utb6",
    IDENTITY_POOL_ID: "us-east-1:88d5520a-c91b-4055-b15f-afc97aa97e0c"
  }
};

const prod = null // Configure production settings here

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev

export default {
  ...config
}