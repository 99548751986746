import {Component, Fragment} from "react"
import {Card, CardBody} from "reactstrap"
import React from "react"
import {EditFragment} from "./Fragments/EditFragment"
import {HeaderBar} from "./HeaderBar"
import {TextFragment} from "./Fragments/TextFragment"
import {TokensFooter} from "./TokensFooter"
import {ImageFragment} from "./Fragments/ImageFragment"
import {WordFragment} from "./Fragments/WordFragment"
import {LoadingFragment} from "./LoadingFragment";

export class DocFragment extends Component {
  state = {
    editMode: this.props.editMode ? this.props.editMode : false,
    content: this.props.content ? this.props.content : "",
    words: this.props.words ? this.props.words : [],
    kanji: this.props.kanji ? this.props.kanji : [],
    previousProps: this.props,
    known: this.props.known && this.props.isInList !== undefined ? this.props.known : false,
    isInList: this.props.isInList && this.props.isInList !== undefined ? this.props.isInList : true
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props === prevProps) {
      return
    }
    this.setState({...this.props})
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }
  editClicked = async event => {
    this.setState({editMode: true})
  }

  cancelClicked = event => {
    this.setState({editMode: false})
  }

  updateClicked = async (content) => {
    this.setState({editMode: false, content:content})
    this.props.updateFunction(content, this.props.fragmentId)
  }

  deleteClicked = async event => {
    this.setState({editMode: false})
    this.props.deleteFunction(this.props.fragmentId)
  }

  markWordKnown = async event => {
    this.setState({known:true})
    this.props.markWordAsKnownFunction(this.props.rawWord.word, this.state.fragmentId)
  }

  markWordUnknown = async event => {
    this.setState({known:false})
    console.log("removal requested")
    this.props.markWordAsUnknownFunction(this.props.rawWord.word, this.state.fragmentId)
  }

  addToList = async event => {
    this.setState({isInList:true})
    this.props.addToListFunction([this.props.rawWord.word], null, this.state.fragmentId)
  }

  removeFromList = async event => {
    this.setState({isInList:false})
    this.props.removeFromListFunction([this.props.rawWord.word], null, this.state.fragmentId)
  }

  render() {
    return (
      <div className="my-3">
        <Card>
          {
            this.state.editMode ?
              <EditFragment cancelClicked={this.cancelClicked} requestUpdate={this.updateClicked} content={this.state.content}/>
              : !this.state.editMode && this.props.fragmentType === "text" ?
                <Fragment>
                  <HeaderBar deleteClicked={this.deleteClicked} editClicked={this.editClicked}/>
                  <TextFragment content={this.state.content}/>
                  <TokensFooter words={this.state.words} kanji={this.state.kanji} pinnedWords={this.props.pinnedWords}
                    saveWordFunction={this.props.saveWordFunction} fragmentOrder={this.props.fragmentOrder}/>
                </Fragment>
                : !this.state.editMode && this.props.fragmentType === "image" ?
                  <Fragment>
                    <HeaderBar deleteClicked={this.deleteClicked} editClicked={this.editClicked}/>
                    <ImageFragment link={this.props.link} sk={this.props.sk} content={this.props.content}/>
                    <TokensFooter words={this.state.words} kanji={this.state.kanji} pinnedWords={this.props.pinnedWords}
                      saveWordFunction={this.props.saveWordFunction} fragmentOrder={this.props.fragmentOrder}/>
                  </Fragment>
                  : !this.state.editMode && this.props.fragmentType === "word" && this.props.rawWord ?
                    <Fragment>
                      <HeaderBar deleteClicked={this.deleteClicked} markWordKnown={this.markWordKnown} markWordUnknown={this.markWordUnknown} known={this.state.known} addToListFunction={this.addToList} removeFromListFunction={this.removeFromList} isInList={this.state.isInList}/>
                      <CardBody>
                        <WordFragment {...this.props.rawWord} />
                      </CardBody>
                    </Fragment>
                        : this.props.fragmentType === "loading" ?
                            <LoadingFragment/>
                    : ""
          }
        </Card>
      </div>
    )
  }
}