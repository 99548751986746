import React, {Fragment} from "react"

export const Furigana = ({text, readings}) => {
    const textWithFurigana = text ? [...text].map((c, i) => {
        const furi = readings ? readings.find(x => x.kanji === c) : null
        return furi && furi.reading ? <ruby key={text+i}>
            {c}
            <rp>{"("}</rp>
            <rt>{furi.reading}</rt>
            <rp>{")"}</rp>
        </ruby> : c
    }) : null
    return <Fragment>{textWithFurigana}</Fragment>
}