import React, {Fragment} from "react"
import {Button} from "reactstrap"
import "./LoaderButton.css"

export const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}>
    {isLoading ?
      <Fragment>
        {loadingText}<div className="lds-dual-ring"/>
      </Fragment>
      : text
    }
  </Button>
