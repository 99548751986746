import React, {Component} from "react"
import {API, Storage} from "aws-amplify"
import {Button, FormGroup, Label, Input, Card} from "reactstrap"
import {UploadComponent} from "../components/UploadComponent"
import {DocFragment} from "../components/Document/DocFragment"
import {UserContext} from "../UserContext";
import {LoadingFragment} from "../components/Document/LoadingFragment";

export default class Document extends Component {

  static contextType = UserContext

  state = {
    title: "",
    fragments: [],
    urls: [],
    pinnedWords: []
  }

  // ----- API -----
  readDocument = async () => {
    return API.get("documentsGateway", `document/read/${this.props.match.params.id}`)
  }

  createFragment = async (fragment) => {
    return API.post("documentsGateway", "fragment/create", {
      body: fragment
    })
  }

  createFragments = async (fragments) => {
    return API.post("documentsGateway", "fragment/createManyFragment", {
      body: fragments
    })
  }

  readFragments = async () => {
    return API.get("documentsGateway", `fragment/readAll/${this.props.match.params.id}`)
  }

  readFragment = async (documentId, fragmentId) => {
    return API.get("documentsGateway", `fragment/read/${documentId}/${fragmentId}`)
  }

  updateFragment = async (fragment) => {
    return API.put("documentsGateway", "fragment/update", {
      body: fragment
    })
  }

  deleteFragment = async (fragment) => {
    return API.del("documentsGateway", "fragment/delete", {
      body: fragment
    })
  }

  readWords = async (words) => {
    return API.get("documentsGateway", "word/readMany", {"queryStringParameters": {"words": words.join(",")}})
  }

  readWord = async (word) => {
    return this.readWords([word]).then(ws => ws[0])
  }

  markWordsKnownNAddToUI = async (words, fId) => {
    return this.markWordsAsKnown(words).then(() => this.updateFragmentInUI(fId, ((f) => {
        f.known = true
        return f
    }))) //.then(() => this.context.refreshUser()) // .then(() => this.refreshKnownWordsInFragments())
  }
    markWordsAsKnown = async (words, fId) => {
        return API.put("documentsGateway", "user/addWordsToList", {
            body: words
        })
    }

    addToListNUpdateUI = async (words, kanji, fId) => {
      return this.addToList(words, kanji).then(() => this.updateTextFragmentInUI(fId, ((f) => {
          f.isInList = true
          return f
      })))
    }

    removeFromListNUpdateUI = async (words, kanji, fId) => {
        return this.removeFromList(words, kanji).then(() => this.updateTextFragmentInUI(fId, ((f) => {
            f.isInList = false
            return f
        })))
    }

    addToList = async (words, kanji) => {
      return API.put("documentsGateway", "list/addToCustomList", {
          body: {sk: "lst-" + this.state.sk, words:words, kanji:kanji}
      })
    }

    removeFromList = async (words, kanji) => {
        return API.del("documentsGateway", "list/removeFrom", {
            body: {sk: "lst-" + this.state.sk, words:words, kanji:kanji}
        })
    }

    markWordUnknownNUpdateUI = async (words, fId) => {
        return this.markWordAsUnknown(words).then(() => this.updateFragmentInUI(fId, ((f) => {
            f.known = false
            return f
        })))
        //.then(() => this.context.refreshUser()) //.then(() => this.refreshKnownWordsInFragments())
    }

    markWordAsUnknown = async (words) => {
        return API.del("documentsGateway", "user/removeWordFromList", {
            body: words
        })
    }

  retrieveURLs = async (fragments) => {
    return Promise.all(
      fragments.filter((f) => (f.fragmentType === "image"))
        .map((k) => Storage.vault.get(k.fnk)
          .then(l => {
              k.link = l
              return k
            }
          )))
  }

  // ----- END of API -----
  createEmptyTextFragment = async () => {
    const emptyTextFragment = {
      fragmentType: "text",
      hk: this.state.sk,
      fragmentOrder: this.state.fragments.length,
      editMode: true
    }
    const insertedFragment = await this.createFragment(emptyTextFragment)
    insertedFragment.editMode = true
    insertedFragment.content = ""
    this.addFragmentToUI(insertedFragment)
  }

  createImageFragment = async (fileKey) => {
    const imageFragment = {
      fragmentType: "image",
      content: fileKey,
      fnk: fileKey,
      hk: this.state.sk,
      fragmentOrder: this.state.fragments.length
    }
    this.createFragment(imageFragment)
      .then(f => this.refreshURL(imageFragment)
        .then(url => this.addFragmentToUI(imageFragment)))
  }

  createImageFragments = async (fileKeys) => {
    const imageFragments = this.buildImageFragments(fileKeys)
    const loadingFragments = fileKeys.map((fileKey, i) => this.buildLoadingFragment(this.state.fragments.length + i))

    this.addFragmentsToUI(loadingFragments)

    this.createFragments(imageFragments)
      .then(f => this.refreshURLs(f)
        .then(fragsWithLinks => this.deleteFragmentsFromUI(loadingFragments)
          .then(x => this.addFragmentsToUI(fragsWithLinks)
            .then(c => this.readAndUpdateManyFragments(fragsWithLinks, 4000)))
        ))
  }

  buildImageFragment = (filekey) => this.buildImageFragments([filekey])[0]
  buildImageFragments = (fileKeys) => {
    return fileKeys.map((fileKey, i) => ({
      fragmentType: "image",
      content: fileKey,
      fnk: fileKey,
      hk: this.state.sk,
      fragmentOrder: (this.state.fragments.length + i)
    }))
  }

  createWordFragment = async (word, originatingFragment, order) => {
    console.log("Framgment order is")
    console.log(order)
    const wordFragment = {
      fragmentType: "word",
      content: word,
      hk: this.state.sk,
      originatingFragment: originatingFragment,
      fragmentOrder: order
    }
    // Add temp loading fragment to UI
    this.addPinnedWord(word)
    this.addLoadingFragmentToUI(order)

    // Create Fragment on the backend
    this.createFragment(wordFragment)
      .then(fr => this.readWord(word)
        .then(w => {
            // Remove Loading Fragment
            this.deleteFragmentFromUI("lf-" + order)
            this.removePinnedWord(word)

            // Add actual word fragment to UI
          fr.rawWord = w
          this.addFragmentToUI(fr).then(() => this.addPinnedWord(word))
        }))
  }

  expandWordFragment = async (word, originatingFragment, order) => {
      this.createWordFragment(word, originatingFragment, order).then(r => this.addToList([word], null))
  }

    readList = async () => {
        return API.get("documentsGateway", "list/read", {"queryStringParameters": {"lst": "lst-" + this.state.sk}})
    }

    refreshLists = async () => {
        this.readList()
            .then(lsts => this.setState({wordsInList: lsts.words, kanjiInLists: lsts.kanji}))
            .catch((e) => this.setState({wordsInList: [], kanjiInLists: []}))
    }

    refreshListsNUpdateUI = async () => {
        return this.readList().then(lst => this.updateFragmentInUIWithFilter(((tempFrag) => {
            // console.log("tempfrag")
            // console.log(tempFrag)
            // console.log(lst.words.filter(w => w === tempFrag.word).length === 0)
            return tempFrag.fragmentType === "word" && lst.words.filter(w => w === tempFrag.content).length === 0
        }), ((f) => {
            // console.log(lst.words)
            f.isInList = false
            return f
        })))
    }

  // ---- FrontEnd API ----
    buildLoadingFragment = (order) => {
        return {
            fragmentType: "loading",
            fragmentOrder: order,
            sk: "lf-" + order
        }
    }

    addLoadingFragmentToUI = async (order) => {
        return this.addFragmentToUI(this.buildLoadingFragment(order))
    }

  readAndUpdateSingleFragment = async (documentId, fragmentId, delay, precalculatedURL) => {
    setTimeout(() => {
      this.readFragment(this.state.sk, fragmentId).then(f => {
        f.link = precalculatedURL
        const fragments = this.state.fragments
        fragments[fragments.findIndex(f => f.sk === fragmentId)] = f
        this.forceUpdate() // re-render
      })
    }, delay)
  }

  readAndUpdateManyFragments = async (fragments, delay) => {
    setTimeout(() => {
      var i;
      for (i = 0; i < fragments.length; i++) {
        const frag = fragments[i]
        this.readAndUpdateSingleFragment(frag.hk, frag.sk, 0, frag.link)
      }
    }, delay)
  }

  addFragmentToUI = async (fragment) => {
    this.setState(prevState => ({
      fragments: [...prevState.fragments, fragment]
    }))
  }

  addFragmentsToUI = async (fragments) => {
    this.setState(prevState => ({
      fragments: [...prevState.fragments, ...fragments]
    }))
  }

  updateTextFragmentInUI = (text, fragmentId) => {
    const fragment = this.state.fragments.find(f => f.sk === fragmentId)
    fragment.content = text
    return fragment
  }

    updateFragmentInUI = (fragmentId, mapF) => {
        var i;
        for (i = 0; i < this.state.fragments.length; i++) {
            const tempFrag = this.state.fragments[i]
            if (tempFrag.sk === fragmentId) {
                const frags = [...this.state.fragments]
                const moddedFrag = mapF(tempFrag)
                frags[i] = moddedFrag
                this.setState({fragments: frags})
            }
        }
    }

    updateFragmentInUIWithFilter = (filterF, mapF) => {
        var i;
        for (i = 0; i < this.state.fragments.length; i++) {
            const tempFrag = this.state.fragments[i]
            if (filterF(tempFrag)) {
                const frags = [...this.state.fragments]
                const moddedFrag = mapF(tempFrag)
                frags[i] = moddedFrag
                this.setState({fragments: frags})
            }
        }
    }

  deleteFragmentFromUI = async (fragmentId) => {
    this.setState(prevState => ({fragments: prevState.fragments.filter(fragment => fragment.sk !== fragmentId)}))
  }

  deleteFragmentsFromUI = async (fragsToDelete) => {
    this.setState(prevState => ({fragments: prevState.fragments.filter(fragment => fragsToDelete.filter(deletionFragment => deletionFragment.sk !== fragment.sk).length > 0)}))
  }
  // ---- End of FrontEnd API ----

  handleTFUpdate = async (text, fragmentId) => {
    const fragment = this.updateTextFragmentInUI(text, fragmentId)
    this.updateFragment(fragment).then(() => this.readAndUpdateSingleFragment(this.state.sk, fragmentId, 3000))
  }

  handleFDelete = async (fragmentId) => {
    const fragment = this.state.fragments.find(f => f.sk === fragmentId)
    const deletionTarget = {hk: fragment.hk, sk: fragment.sk}

    // Delete fragment locally & unpin word
    this.deleteFragmentFromUI(fragmentId)
    if (fragment.fragmentType === "word" || fragment.fragmentType === "kanji") this.removePinnedWord(fragment.content)

    // Delete Fragment from remote, if it fails reverse everything
    this.deleteFragment(deletionTarget)
        .catch(e => {
            this.addFragmentToUI(fragment)
            if (fragment.fragmentType === "word" || fragment.fragmentType === "kanji") this.addPinnedWord(fragment.content)
        }) // Untested
  }

  refreshURLsForAllFragments = async () => {
    return this.retrieveURLs(this.state.fragments).then(u => this.setState({urls: u}))
  }

  refreshURLs = async (fragments) => {
    // return this.retrieveURLs(fragments).then(u => this.addUrls(u))
    return this.retrieveURLs(fragments)
  }

  refreshURL = async (fragment) => {
    return this.retrieveURLs([fragment]).then(u => this.addUrl(u))
  }

  refreshDocument = async () => {
    return this.readDocument().then(d => {
      this.setState({...d})
      document.title = (d.title ? d.title + " - " : "") + "Documents - Kanji App"
    })
  }

  refreshPinnedWords = async () => {
    return this.setState({pinnedWords: this.state.fragments.filter(f => f.fragmentType === "word").map(f => f.content)})
  }

  addPinnedWord = async (word) => {
    return this.setState(prevState => ({
      pinnedWords: [...prevState.pinnedWords, word]
    }))
  }

  addUrl = async (url) => {
    return this.setState(prevState => ({
      urls: [...prevState.urls, url]
    }))
  }
  addUrls = async (urls) => {
    return this.setState(prevState => ({
      urls: [...prevState.urls, ...urls]
    }))
  }

  removePinnedWord = async (word) => {
    return this.setState({pinnedWords: this.state.pinnedWords.filter(pw => pw !== word)})
  }

  refreshWords = async () => {
    return this.readWords(this.state.fragments.filter(f => f.fragmentType === "word").map(f => f.content)).then(ws => this.setState({fragments: this.matchWordsToFragments(ws, this.state.fragments)})).then((x) => this.refreshPinnedWords())
  }

  refreshFragments = async () => {
    return this.readFragments().then(fs => this.setState({fragments: fs}))
  }

  refreshFragmentsFull = async () => {
    return this.refreshFragments()
      .then(fs => this.refreshURLsForAllFragments()
        .then(urls => this.refreshWords()
            .then(wrds => this.refreshListsNUpdateUI())))
  }

  refreshFullDocument = async () => {
    return this.refreshDocument()
      .then(d => this.refreshFragmentsFull())
  }

  matchWordsToFragments = (words, fragments) => {
    return fragments.map(f => {
      if (f.fragmentType === "word") {
        return this.matchWordToFragments(words, f)
      } else {
        return f
      }
    })
  }
  matchWordToFragments = (words, fragment) => {
    fragment.rawWord = words.find(w => w.word === fragment.content)
    fragment.known = this.context && this.context.user ? this.context.user.knownWords.includes(fragment.content) : false
    return fragment
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) return
    document.title = "Documents - Kanji App"
    this.refreshFullDocument()
  }

  render() {
    console.log("printing fragments")
    console.log(this.state.fragments)
    const fragments = this.state.fragments
      .sort((a, b) => (a.fragmentOrder > b.fragmentOrder) ? 1 : ((b.fragmentOrder > a.fragmentOrder) ? -1 : 0))
      .map(f =>
          <DocFragment
            key={f.sk}
            fragmentId={f.sk}
            content={f.content}
            words={f.words}
            kanji={f.kanji}
            fragmentType={f.fragmentType}
            link={f.link}
            updateFunction={this.handleTFUpdate}
            deleteFunction={this.handleFDelete}
            saveWordFunction={this.expandWordFragment}
            rawWord={f.rawWord}
            pinnedWords={this.state.pinnedWords}
            editMode={f.editMode}
            markWordAsKnownFunction={this.markWordsKnownNAddToUI}
            markWordAsUnknownFunction={this.markWordUnknownNUpdateUI}
            addToListFunction={this.addToListNUpdateUI}
            removeFromListFunction={this.removeFromListNUpdateUI}
            known={f.known !== undefined ? f.known : false} //faster than settings state on DocFragment
            isInList={f.isInList !== undefined ? f.isInList : true} //faster than settings state on DocFragment
            fragmentOrder={f.fragmentOrder}
          />
      )
    return (
      <div id="document" className="mx-auto p-2" style={{maxWidth: "1280px"}}>
        <h1>{this.state.title}</h1>
        {fragments}
        <div>
          {/*<LoadingFragment/>*/}
          <Card className="text-center my-2 p-2 border-primary">
            <div className="container">
              <div className="row my-2">
                <div className="col-sm">
                  <button className="my-2 btn btn-outline-primary" onClick={this.createEmptyTextFragment}>^Insert a New Text
                    Fragment</button>
                </div>
                <div className="col-sm">
                  <UploadComponent callBackF={this.createImageFragments}/>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    )
  }
}
