import React, {Component, Fragment} from "react"
import {Input} from "reactstrap"
import Circle from "react-circle"
import {Storage} from "aws-amplify"


export class UploadComponent extends Component {
  state = {
    files: [],
    progress: 0,
    progressDisplay: 0,
    uploadedAmount: 0
  }

  handleInputFileChange = (event) => {
    const keys = Array.from(event.target.files).sort()

    const totalFileSize = keys.reduce((z, i) => z + i.size, 0)
    // console.log("total file size is:", totalFileSize)

    this.setState({files: keys.map(f => f.name), progress:0, uploadedAmount:0})

    this.splitIntoChunks(keys, 25).map(ks => Promise.all(ks.map(f => this.uploadToS3(f, totalFileSize))).then(keys => {
      if (this.state.files.length === 0) {
        this.setState({files:[], progress:0, uploadedAmount:0}, () => this.props.callBackF(keys))
      } else {
        this.props.callBackF(keys)
      }
    }))
  }

  splitIntoChunks = (inputArray, perChunk) => {
    return inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }

  uploadToS3 = async (file, totalFileSize) => {
    const filename = `${Date.now()}-${file.name}`

    let uploadedAmount = 0
    const sumP = this.sumProgress

    const stored = await Storage.vault.put(filename, file, {
      contentType: file.type,
      progressCallback(progress) {
        // console.log("Uploaded amount before is:" + uploadedAmount)
        const increase = progress.loaded - uploadedAmount
        const percentIncrease = increase * 100 / totalFileSize
        // console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
        // console.log("Working on file: " + file.name +", increase is: " + increase)
        // console.log("Working on file: " + file.name + " %increase is: " + percentIncrease)
        // self.sumProgress(percentIncrease)
        sumP(percentIncrease)
        // const np = parseFloat(self.state.progress) + parseFloat(percentIncrease)
        // const npd = (np).toFixed(2)
        // self.setState({progress: np, progressDisplay: npd})
        uploadedAmount = progress.loaded
        // console.log("Total percent is: ", self.state.progress)
        // console.log("Uploaded amount after is:" + uploadedAmount) //the total amount is added at the end
      }
    })

    this.increaseProgress(file.size, file.name)
    return stored.key
  }

  sumProgress = (percentIncrease) => {
    const np = parseFloat(this.state.progress) + parseFloat(percentIncrease)
    const npd = (np).toFixed(2)
    this.setState({progress: np, progressDisplay: npd})

  }

  increaseProgress = (uploadAmount, fileToRemove) => {
    this.setState({
      uploadedAmount: this.state.uploadedAmount + uploadAmount,
      files: this.state.files.filter(f => f !== fileToRemove)
    })
  }

  render() {
    return (
      <div className="text-center">
        {this.state.files && this.state.files.length > 0 ?
          <Circle progress={this.state.progressDisplay} progressColor="#FF304F" textColor="#FFF"/>
          :
          <Fragment>
            <p>Or upload an image</p>
            <Input type="file" multiple className="text-center" onChange={this.handleInputFileChange}/>
          </Fragment>
        }


      </div>
    )
  }
}