import React, {Component, useState} from "react"
import {Link} from "react-router-dom"
import {UserContext} from "../UserContext"
import {API} from "aws-amplify";
import {Map} from "immutable"

export default class Lists extends Component {

    static contextType = UserContext

    state = {
        lists: Map({})
    }

    async componentDidMount() {
        document.title = "Lists - Kanji App"
        if (!this.props.isAuthenticated) return
        this.context.refreshLists()
            .then(ls =>
                this.setState(
                    {lists: Map(ls.map(l => ([l.sk, l])))}
                ))
        console.log(this.context.lists)

    }

    handleDelete = async (sk) => {
        this.setState({lists: this.state.lists.remove(sk)})
    }

    render() {

        const lists = this.state.lists.toArray().map((item, i) => <ListItem list={item[1]}
                                                                            deleteHandlerF={this.handleDelete}
                                                                            key={item[0]}/>)

        return (
            <div id="lists" className="mx-auto p-2" style={{maxWidth: "1280px"}}>
                <div id="title" className="d-flex justify-content-between mt-2 mb-3 mx-2">
                    <h2 className="bd-title">Lists</h2>
                    {/*<Link to="/documents/new" className="btn btn-primary">New List</Link>*/}
                </div>
                <ul className="list-group mt-2 mx-2" id="document-list">
                    {lists}
                </ul>
            </div>
        )
    }
}


const ListItem = ({list, deleteHandlerF}) => {
    const [editMode, setEditMode] = useState(false)
    const [title, setTitle] = useState(list.title)

    const contentChange = async event => {
        setTitle(event.target.value)
    }

    const shortcutPressed = async event => {
        if (event.ctrlKey && event.key === "Enter") {
            // setEditMode(false)
            saveTitleChanges()
        }
    }

    const keyDown = async event => {
        if (event.key === "Escape") {
            setEditMode(false)
        }
    }

    const enterEditMode = async event => {
        setEditMode(true)
    }

    const exitEditMode = async event => {
        setEditMode(false)
    }

    const saveTitleChanges = async () => {
        setEditMode(false)
        saveToDB(title)
    }

    const saveToDB = async (title) => {
        return API.put("documentsGateway", "list/update", {
            body: {sk: list.sk, title: title}
        })
    }

    const deleteItem = async () => {
        deleteHandlerF(list.sk)
        return API.del("documentsGateway", "list/delete", {
            body: {sk: list.sk}
        })
    }

    return editMode === false ?
        <li className="list-group-item">
        <span style={{float: "left"}}>
                        <Link to={`/lists/${list.sk}`}>{title}</Link>
        </span>
            <span style={{float: "right"}}>
            <a className="text-danger" onClick={deleteItem}>Delete</a>
        </span>
            <span className="mx-3" style={{float: "right"}}>
            <a className="text-danger" onClick={enterEditMode}>Rename</a>
        </span>
        </li>
        :
        <li className="list-group-item">
        <span style={{float: "left"}}>
            <input
                className="w-100"
                ref={input => input && input.focus()}
                value={title}
                onChange={contentChange}
                onKeyPress={shortcutPressed}
                onKeyDown={keyDown}
            />
            </span>
            <span style={{float: "right"}}>
            <a className="text-danger" onClick={exitEditMode}>Cancel</a>
        </span>
            <span className="mx-3" style={{float: "right"}}>
            <a className="text-danger" onClick={saveTitleChanges}>Save</a>
        </span>
        </li>
}
