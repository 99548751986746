import React, {Component} from "react"
import {FormGroup, Label, Input} from "reactstrap"
import {Auth} from "aws-amplify"
// import {UserContext} from "../UserContext"
import {LoaderButton} from "../components/LoaderButton"

export default class ForgotPassword extends Component {
  // static contextType = UserContext

  state = {
    email: "",
    isLoading: false,
    errorMsg: "",
    successMsg: ""
  }

  async componentDidMount() {
    document.title = "Forgot Password - Kanji App"
    // if (this.props.isAuthenticated) return
  }

  validateConfirmationForm() {
    return this.state.email.length > 3
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleResetSubmit = async event => {
    event.preventDefault()

    this.setState({isLoading: true})


    Auth.forgotPassword(this.state.email)
      .then(u => this.correctResetPassword(u))
      .catch(e => this.incorrectResetPassword(e))
  }


  correctResetPassword = u => {
    this.setState({isLoading: false, successMsg:"Your account recovery code has been sent to your email."})
    this.props.history.push({
      pathname: "/reset",
      props: { email: this.state.email }
    })
  }

  incorrectResetPassword = e => {
    // this.setState({errorMsg : e.message, isLoading: false})
    this.setState({isLoading:false})
  }

  render() {
    return (
      <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
        <h1>Reset your password</h1>
        <p className="lead">Enter your email address and we'll send you a recovery link.</p>
        <form onSubmit={this.handleResetSubmit}>
          <FormGroup>
            <Label for="code" className="form-control-label">Email</Label>
            <Input
              autoFocus
              type='tel'
              value={this.state.email}
              onChange={this.handleChange}
              id="email"
            />
          </FormGroup>
          {this.state.errorMsg ? <p className="text-danger">{this.state.errorMsg}</p> : null}
          {this.state.successMsg ? <p className="text-success">{this.state.successMsg}</p> : null}

          <LoaderButton
            color="primary" size="lg" block type="submit" text="Send Reset Confirmation"
            loadingText="Sending reset confirmation"
            isLoading={this.state.isLoading}
          >Send Reset Confirmation</LoaderButton>
        </form>
      </div>
    )
  }
}