import {UserContext} from "../UserContext"
import {API} from "aws-amplify"
import React, {Component} from "react"
import {Link} from "react-router-dom";
import {GenericTable} from "../components/GenericTable";

export default class Kanjis extends Component {

    static contextType = UserContext

    state = {
        search: "",
        knownWKanji: [],
        allKanji: [],
        page: 1
    }

    async componentDidMount() {
        document.title = "Kanji - Kanji App"
        if (!this.props.isAuthenticated) return
        // this.context.refreshUser()
        this.setState({allKanji: this.context.allKanji ? this.context.allKanji : []})
        this.readAllKanji().then(k => this.setState({allKanji: k}))
    }

    addKanjiToList = async (kanji) => {
        return API.put("documentsGateway", "user/addKanjiToList", {
            body: kanji
        })
    }

    removeKanjiFromList = async (kanji) => {
        return API.put("documentsGateway", "user/removeKanjiFromList", {
            body: kanji
        })
    }

    readAllKanji = async () => {
        return API.get("documentsGateway", "kanji/summary")
    }


    itemMatchesTerm = (item, term) => item.kanji.includes(term)
        || (item.meanings && item.meanings.includes(term))
        || (item.romajiKun && item.romajiKun.includes(term))
        || (item.romajiOn && item.romajiOn.includes(term))

    itemToRow = (item) => {
        return <tr key={item.kanji}>
            <th scope="row" style={{fontWeight: "normal"}}><Link to={"/kanji/" + item.kanji}>{item.kanji}</Link></th>
            <td>{item.romajiKun}</td>
            <td>{item.romajiOn}</td>
            <td>{item.meanings ? item.meanings : ""}</td>
        </tr>
    }

    render() {
        const kt = <GenericTable items={this.state.allKanji}
                                 itemsPerPage={15}
                                 itemToRow={this.itemToRow}
                                 columnNames={["Kanji", "KunYomi", "OnYomi", "Meaning"]}
                                 itemMatchesTerm={this.itemMatchesTerm}/>
        return (
            <div className="KnownKanji m-2" className="mx-auto p-2" style={{maxWidth: "1280px"}}>
                <h1>Kanji</h1>
                {kt}
            </div>
        )
    }
}

