import React, { Component, Fragment } from "react"
import Routes from "./Routes"
import { Link } from "react-router-dom"
import { Navbar, NavbarBrand, NavItem, Nav, NavLink } from "reactstrap"
import "./App.css"
import { Auth, API } from "aws-amplify"
import {UserContext} from "./UserContext"

class App extends Component {
  state = {
    isAuthenticated: false,
    email: "",
    isAuthenticating: true,
    lists: [],
    documents: [],
    // Binds context functions
    refreshUser: this.refreshUser.bind(this),
    refreshDocs: this.refreshDocs.bind(this),
    refreshLists: this.refreshLists.bind(this),
    createDoc: this.createDoc.bind(this),
    deleteDoc: this.deleteDocument.bind(this),
    login: this.login.bind(this)
  }

  //  ---- API ----
  readUser = async () => {
    const user = await API.get("documentsGateway", "user/read")
    return user
  }

  refreshUser(){
    this.readUser().then(u => this.setState({user: u, lists: u.lists}))
  }

  async refreshDocs(){
    // return API.get("documentsGateway", "document/readAll")
    const newUser = await this.readUser()
    this.setState({user:newUser, documents: newUser.documents})
    // this.readUser().then(u => this.setState({user: u}))
    return newUser.documents
  }

  async refreshLists(){
      const newUser = await this.readUser()
      this.setState({user: newUser, lists: newUser.lists})
      return newUser.lists
    // return this.readUser().then(u => this.setState({user: u, lists: u.lists}))
  }

  createDoc(item) {
    return API.post("documentsGateway", "document/create", {
      body: item
    })
  }

  async deleteDocument(id) {
    await API.del("documentsGateway", "document/delete", {
      body: {
        sk: id
      }
    })
    // this.refreshDocs()
  }

  async readAllKanji() {
    return API.get("documentsGateway", "kanji/summary")
  }
  async readAllWords() {
    return API.get("documentsGateway", "word/summary")
  }

  refreshWords(){
    this.readAllWords().then(aw => this.setState({allWords: aw}))
  }

  refreshKanji(){
    this.readAllKanji().then(ak => this.setState({allKanji: ak}))
  }

  async login(user, pass)  {
    const authResult = Auth.signIn(user, pass)
    try {
      const user = await authResult
      this.setState({ isAuthenticated: true, email: user })
    } catch (e) {}
    return authResult
  }

  // ---- ... ----

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.setState({ isAuthenticated: true })
        //console.log("loggined in") // this.setAuthenticatedEmail(Auth.currentSession().)
        this.refreshUser()
        this.refreshKanji()
        this.refreshWords()
      }
    } catch (e) {
      if (e !== "No current user")  alert(e)
    }
    this.setState({ isAuthenticating: false })
  }

  handleLogout = async event => {
    await Auth.signOut()
    this.setState({ isAuthenticated: false, email:"" })
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user
    }

    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <UserContext.Provider value={this.state}>
          <Fragment>
            <Navbar className="ml-auto">
              <NavbarBrand tag={Link} to="/">Kanji App</NavbarBrand>
              <Nav className="pull-right">
                {this.state.isAuthenticated
                  ? <Fragment>
                    <NavItem><NavLink to="/kanji" tag={Link}>Kanji</NavLink></NavItem>
                    <NavItem><NavLink to="/words" tag={Link}>Words</NavLink></NavItem>
                      <NavItem><NavLink to="/lists" tag={Link}>Lists</NavLink></NavItem>
                      <NavItem><NavLink to="/documents" tag={Link}>Documents</NavLink></NavItem>
                    <NavItem><NavLink onClick={this.handleLogout} tag={Link} to ="#">Log Out</NavLink></NavItem>
                  </Fragment>
                  : <Fragment>
                    <NavItem><NavLink tag={Link} to="/login">Login</NavLink></NavItem>
                    <NavItem><NavLink tag={Link} to="/signup" className="btn btn-outline-primary">Sign Up</NavLink></NavItem>
                  </Fragment>
                }
              </Nav>
            </Navbar>
            <Routes childProps={childProps} />
          </Fragment>
          <footer className="my-5 pt-5 text-muted text-center text-small ">
            <p className="mb-1">© 2019 Company Name</p>
            <ul className="list-inline">
              <li className="list-inline-item"><Link to="/">Privacy</Link></li>
              <li className="list-inline-item"><Link to="/">Terms</Link></li>
              <li className="list-inline-item"><Link to="/">Support</Link></li>
            </ul>
          </footer>
        </UserContext.Provider>
      </div>
    )
  }
}

// const TopNavBar = () =>
//   <Fragment>
//     <Navbar className="ml-auto">
//       <NavbarBrand tag={Link} to="/">Notes App</NavbarBrand>
//       <Nav className="pull-right">
//         {this.state.isAuthenticated
//           ? <Fragment>
//             <NavItem><NavLink to="/words" tag={Link}>Words</NavLink></NavItem>
//             <NavItem><NavLink to="/documents" tag={Link}>Documents</NavLink></NavItem>
//             <NavItem><NavLink onClick={this.handleLogout} tag={Link} to ="#">Log Out</NavLink></NavItem>
//           </Fragment>
//           : <Fragment>
//             <NavItem><NavLink tag={Link} to="/login">Login</NavLink></NavItem>
//             <NavItem><NavLink tag={Link} to="/signup" className="btn btn-outline-primary">Sing Up</NavLink></NavItem>
//           </Fragment>
//         }
//       </Nav>
//     </Navbar>
//     <Routes childProps={childProps} />
//   </Fragment>


export default App
