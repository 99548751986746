import React, {Component} from "react"
import {Button, FormGroup, Label, Input} from "reactstrap"
import * as qs from "query-string"
import {API, Auth} from "aws-amplify"
import {UserContext} from "../UserContext"

export default class Confirm extends Component {
  static contextType = UserContext

  state = {
    confirmationCode: "",
    email: "",
    isLoading: false,
    errorMsg: "",
    sentConfirmationEmail: false
  }

  async componentDidMount() {
    // if (this.props.isAuthenticated) return
    const params = this.props.location ? qs.parse(this.props.location.search) : ""
    const cc = params.cc ? params.cc : ""

    // const propsEmail= params && params.props.email ? params.props.email : ""
    // console.log("props email" + propsEmail)

    const email = this.props.email ? this.props.email : localStorage.getItem("tempEmail") ? localStorage.getItem("tempEmail") : ""
    this.setState({confirmationCode: cc, email:email})
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault()

    this.setState({isLoading: true})

    Auth.confirmSignUp(this.state.email, this.state.confirmationCode)
      .then(u => this.correctVerification(u))
      .catch(e => this.incorrectVerification(e))
  }

  correctVerification = user => {
    // console.log(user)
    // console.log("correct verification")

      localStorage.setItem("userNotLinked", "true")
      if (this.props.location && this.props.location.props && this.props.location.props.tempPass) {
          // console.log("temp pass shit")
          this.context.login(this.state.email, this.props.location.props.tempPass)
              .then(r => this.props.history.push("/"))
              .catch(e => this.setState({errMsg: e}))
      } else {
          // console.log("trying to push to login")
          this.props.history.push("/login")
      }
  }
  resendConfirmation = e => {
    Auth.resendSignUp(this.state.email)
      .then(r => this.setState({sentConfirmationEmail: true}))
      .catch(e => this.setState({errorMsg: e}))
  }

  incorrectVerification = e => {
    console.log("incorrect verification")
    this.setState({errorMsg : e.message, isLoading: false})
  }

  render() {
    return (
      <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
        <h1>Confirm your account</h1>
        <p className="lead">Please enter the code that was sent to your email.</p>
        <form onSubmit={this.handleConfirmationSubmit}>
          <FormGroup>
            <Label for="code" className="form-control-label">Email</Label>
            <Input
              autoFocus
              type='tel'
              value={this.state.email}
              onChange={this.handleChange}
              id="email"
            />
          </FormGroup>
          <FormGroup>
            <Label for="code" className="form-control-label">Confirmation Code</Label>
            <Input
              autoFocus
              type='tel'
              value={this.state.confirmationCode}
              onChange={this.handleChange}
              id="confirmationCode"
            />
          </FormGroup>
          {this.state.errorMsg ? <p className="text-danger">{this.state.errorMsg}</p> : null}
          {this.state.sentConfirmationEmail ? <p><small>Confirmation email sent.</small></p> : <p><small>If you cannot find your confirmation email, please check your spam folder or <button className="btn btn-link btn-sm m-0 p-0" onClick={this.resendConfirmation}>click here to send it again</button></small></p>}
          <Button
            block
            disabled={!this.validateConfirmationForm()}
            type="submit"
            color="primary"
          >Verify</Button>
        </form>
      </div>
    )
  }
}