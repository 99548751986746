import React, {Component} from "react"
import {FormGroup, Label, Input} from "reactstrap"
import {Auth} from "aws-amplify"
import {LoaderButton} from "../components/LoaderButton"
import * as qs from "query-string"
import {UserContext} from "../UserContext"


export default class ResetPassword extends Component {

  componentWillMount() {
    document.title = "Reset Password - Kanji App"
    // if (this.props.isAuthenticated) return
    const params = this.props.location ? qs.parse(this.props.location.search) : ""
    const cc = params.cc ? params.cc : ""
    const email = this.props.email ? this.props.email : (this.props.location && this.props.location.props && this.props.location.props.email) ? this.props.location.props.email : localStorage.getItem("tempEmail") ? localStorage.getItem("tempEmail") : ""
    this.setState({cc: cc})
    this.setState({email: email})
  }

  render() {
    return (<ResetPasswordForm email={this.state.email} cc={this.state.cc}/>)
  }
}

export class ResetPasswordForm extends Component {
  static contextType = UserContext

  state = {
    isLoading: false,
    email: this.props.email ? this.props.email : "",
    password: "",
    confirmationCode: this.props.cc ? this.props.cc : "",
    errorMsg: ""
  }

  validateForm = () => {
    return (
      this.state.email.length > 4 &&
      this.state.password.length > 8 &&
      this.state.confirmationCode > 4
    )
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleSubmit = async event => {
    event.preventDefault()

    this.setState({isLoading: true})
    Auth.forgotPasswordSubmit(this.state.email,
      this.state.confirmationCode,
      this.state.password
    ).then(u => this.afterSuccess(u))
      .catch(e => this.afterError(e))
    // this.setState({isLoading: false})
  }

  afterSuccess = u => {
    // this.setState({isLoading: false})
    this.context.login(this.state.email, this.state.password).then(r => this.props.history.push("/"))
  }

  afterError = e => {
    this.setState({errorMsg: e.message, isLoading: false})
  }

  render() {
    return <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
      <h1>Reset your password</h1>
      <p className="lead">Please enter a new password along with the confirmation code that was sent to your email.</p>
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="email" className="form-control-label">Email</Label>
          <Input type='email' value={this.state.email} onChange={this.handleChange} id="email" autoFocus/>
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">New Password</Label>
          <Input type="password" value={this.state.password} onChange={this.handleChange} id="password"/>
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">Confirmation Code</Label>
          <Input type="tel" value={this.state.confirmationCode} onChange={this.handleChange}
            id="confirmationCode"/>
        </FormGroup>
        {this.state.errorMsg ? <p className="text-danger">{this.state.errorMsg}</p> : null}
        <LoaderButton color="primary" size="lg" block type="submit" text="Reset Password"
          loadingText="Resetting password"
          isLoading={this.state.isLoading}/>
      </form>
    </div>
  }
}
