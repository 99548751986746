import React, {Component, Fragment, useState} from "react"
import {Link} from "react-router-dom"
import {UserContext} from "../UserContext"
import {API} from "aws-amplify";
import {WordFragment} from "../components/Document/Fragments/WordFragment";

export default class List extends Component {

    static contextType = UserContext

    state = {
        title: "",
        numberOfKanji: 0,
        numberOfWords: 0,
        words: [],
        kanji: [],
        queue: [],
        relevantQueue: [],
        nukKanji: 0,
        nukWords: 0,
        // ukKanji
        // ukWords
        studyMode: "none",
        learnSession: [],
        reviewSession: [],
        sessionSize: 10,
        loading: true
    }

    async componentDidMount() {
        document.title = "List - Kanji App"
        if (!this.props.isAuthenticated) return
        this.context.refreshUser()
        this.refreshTitle()
        this.refreshList()
            .then(() => this.refreshUnknowns()
                .then((ukInfo) => this.refreshQueue(ukInfo.ukWords, ukInfo.ukKanji)
                    .then(() => {
                        Promise.all([this.refreshLearnSession(), this.refreshReviewSession()])
                            .then(() => this.setState({loading: false}))
                    })))
    }

    addToQueue = async (item) => {
        return API.put("documentsGateway", "study/addToQueue", {
            body: item
        })
    }

    addNRefreshQueue = async (item, itemPlain) => {
        // {entry, entryType, mastery, nextReview}
        this.setState({
            relevantQueue: [...this.state.relevantQueue.filter(e => e.entry !== itemPlain.entry), itemPlain],
            ukWords: this.state.ukWords.filter(w => w !== itemPlain.entry),
            ukKanji: this.state.ukKanji.filter(w => w !== itemPlain.entry)
        })
        await this.addToQueue(item)
        console.log("addNRefreshQueue called")
    }

    //addToQueueNUpdateUI = async (item) => {relevantQueue.add(item) relevantQueue.remove(priorDuplicates) this.addToQueue(item) removeFromUK}

    readWords = async (words) => {
        return API.get("documentsGateway", "word/readMany", {"queryStringParameters": {"words": words.join(",")}})
    }

    readList = async (id) => {
        return API.get("documentsGateway", "list/read/", {"queryStringParameters": {"lst": this.props.match.params.id}})
    }

    readQueue = async () => {
        return API.get("documentsGateway", "study/readQueue/")
    }

    refreshList = async () => {
        return this.readList().then(list => {
            this.setState({
                ...list,
                "numberOfKanji": list.kanji ? list.kanji.length : 0,
                "numberOfWords": list.words ? list.words.length : 0
            })
            this.refreshTitle()
        })
    }

    refreshTitle = async () => {
        this.setState({title: this.context.user.lists.find(l => l.sk === this.props.match.params.id).title})
    }

    calculateUnknowns = (setA, setB) => {
        return setA.filter(x => !setB.includes(x))
    }

    refreshUnknowns = async () => {
        const ukw = this.calculateUnknowns(this.state.words ? this.state.words : [], this.context.user.knownWords).filter((ukw) => !this.state.queue.filter(qw => qw.entry === ukw).length > 0)
        const ukk = this.calculateUnknowns(this.state.kanji ? this.state.kanji : [], this.context.user.knownKanji).filter((ukw) => !this.state.queue.filter(qw => qw.entry === ukw).length > 0)
        this.setState({
            ukWords: ukw,
            ukKanji: ukk,
            nukKanji: ukk.length,
            nukWords: ukw.length
        })
        return {
            ukWords: ukw,
            ukKanji: ukk,
            nukKanji: ukk.length,
            nukWords: ukw.length
        }
    }

    refreshQueue = async (wordsList, kanjiList) => {
        return this.readQueue()
            .then(rawQueue => {
                const queue = this.filterListRelevantQueue(this.rawQueueToArray(rawQueue), wordsList, kanjiList)
                const relevantQueue = this.filterTimeRelevantQueue(queue, wordsList, kanjiList)
                this.setState({queue: queue, relevantQueue: relevantQueue})
            })
    }

    filterTimeRelevantQueue = (fullQueue, wordsList, kanjiList) => {
        return fullQueue.filter(e => e.nextReview <= Date.now() + (60 * 60 * 1000 * 12) && (wordsList.includes(e.entry) || kanjiList.includes(e.entry)))
    }

    filterListRelevantQueue = (fullQueue, wordsList, kanjiList) => {
        return fullQueue.filter(e => (wordsList.includes(e.entry) || kanjiList.includes(e.entry)))
    }

    rawQueueToArray = (rawQueue) => {
        return Object.entries(rawQueue)
            .map(([k, v]) => {
                const parsedEntry = v
                parsedEntry.entry = k.substring(1);
                parsedEntry.entryType = k[0]
                return parsedEntry
            })
    }

    refreshReviewSession = async () => {
        const reviewEntries = this.state.relevantQueue
            .filter(q => q.nextReview <= Date.now())
            .slice(0, Math.max(0, Math.min(this.state.sessionSize, this.state.relevantQueue.length)))
            .sort((a, b) => a.nextReview > b.nextReview ? -1 : 1)
        this.setState({reviewSession: reviewEntries})
    }

    refreshLearnSession = async () => {
        const newEntries = this.state.ukWords
            .filter(e => this.state.queue.filter(q => q.entry === e).length === 0)
            .slice(0, Math.max(0, Math.min(this.state.sessionSize, this.state.ukWords.length)))
            .map((w) => ({entry: w, entryType: "w", nextReview: Date.now(), mastery: 0}))
            .sort((a, b) => a.nextReview > b.nextReview ? -1 : 1)

        const wordsInfo = await this.readWords(newEntries.map(e => e.entry))

        const fullNewEntries = newEntries.map((e) => {
            const seekedWord = wordsInfo.find(w => w.word === e.entry)
            return {...e, ...seekedWord}
        })

        this.setState({learnSession: fullNewEntries})
    }

    reviewClicked = async () => {
        this.setState({studyMode: "review"})
    }

    learnClicked = async () => {
        this.setState({studyMode: "learn"})
    }

    quizFinished = async () => {
        console.log("quiz finished called")
        this.setState({studyMode: "none", loading: true})
        this.refreshLearnSession().then(() => this.setState({loading: false}))
        this.refreshReviewSession()
    }

    render() {
        return (
            <div className="mx-auto p-2 my-2" style={{maxWidth: "1280px"}}>
                <b><h2 className="bd-title my-2">{this.state.title}</h2></b>
                {this.state.studyMode === "none" ?
                    <DashBoard
                        nukKanji={this.state.nukKanji}
                        numberOfKanji={this.state.numberOfKanji}
                        nukWords={this.state.nukWords}
                        numberOfWords={this.state.numberOfWords}
                        words={this.state.words}
                        kanji={this.state.kanji}
                        numberOfReviews={this.state.reviewSession.length}
                        numberOfLearns={this.state.learnSession.length}
                        reviewF={this.reviewClicked}
                        learnF={this.learnClicked}
                        loading={this.state.loading}
                    />
                    : this.state.studyMode === "learn" ?
                        <Quiz entries={this.state.learnSession}
                              addToQueueF={this.addNRefreshQueue}
                              quizFinishedF={this.quizFinished}
                        />
                        : this.state.studyMode === "review" ?
                            <Quiz entries={this.state.reviewSession}
                                  addToQueueF={this.addNRefreshQueue}
                                  quizFinishedF={this.quizFinished}
                            />
                            :
                            null}
            </div>
        )
    }
}
const DashBoard = ({nukKanji, numberOfKanji, nukWords, numberOfWords, words, kanji, numberOfReviews, numberOfLearns, reviewF, learnF, loading}) => {
    const kanjiProgress = nukKanji ? "(" + (numberOfKanji - nukKanji) + " / " + numberOfKanji + ")" : null
    const wordsProgress = nukWords ? "(" + (numberOfWords - nukWords) + " / " + numberOfWords + ")" : null

    const wordsListString = words && numberOfWords > 0 ? words.join(", ") : null
    const kanjiListString = kanji && numberOfKanji > 0 ? kanji.join(", ") : null

    const wordsProgressRaw = ((numberOfWords - nukWords) * 100 / numberOfWords).toFixed(1)
    const kanjiProgressRaw = ((numberOfKanji - nukKanji) * 100 / numberOfKanji).toFixed(1)

    return <div>
        {wordsProgress ? <h3 className="lead">Words Progress {wordsProgress}</h3> : null}
        {numberOfWords > 0 ? <Progress rawProgress={wordsProgressRaw}/> : null}
        {wordsListString ? <Fragment><h4 className="lead">Words</h4><p>{wordsListString}</p></Fragment> : null}
        {kanjiProgress ? <h3 className="lead">Kanji Progress {kanjiProgress}</h3> : null}
        {numberOfKanji > 0 ? <Progress rawProgress={kanjiProgressRaw}/> : null}
        {kanjiListString ? <Fragment><h4 className="lead">Kanji</h4><p>{kanjiListString}</p></Fragment> : null}

        {!loading ? <div className="d-flex flex-row-reverse">
            {numberOfLearns > 0 ?
                <button className="btn btn-primary m-2" onClick={learnF}>Learn</button>
            :   <button className="btn btn-primary m-2" disabled onClick={learnF}>Learn</button>}
            {numberOfReviews > 0 ?
                <button className="btn btn-outline-primary m-2" onClick={reviewF}>Review ({numberOfReviews})</button>
                : <button className="btn btn-outline-primary m-2" disabled onClick={reviewF}>Review
                    ({numberOfReviews})</button>}
        </div> : null}

    </div>
}

const Progress = ({rawProgress}) =>
    <div>
        <div className="progress my-3" style={{height: "3vh"}}>
            <div className="progress-bar bg-success" role="progressbar"
                 style={{"width": rawProgress + "%", height: "3vh"}}
                 aria-valuenow={rawProgress}
                 aria-valuemin="0"
                 aria-valuemax="100">{rawProgress + "%"}</div>
        </div>
    </div>

const Quiz = ({entries, addToQueueF, quizFinishedF}) => {
    const [entryNumber, setEntryNumber] = useState(0)
    const [currentEntry, setCurrentEntry] = useState(entries[0])
    const [madeAnError, setMadeAnError] = useState(false)

    const buildNextReview = (nextMastery, entryType, entry) => {
        const timeToNextReview = calcTimeToNextReview(nextMastery)
        const nextReview = timeToNextReview + Date.now()

        var reviewUpdate = new Object()
        reviewUpdate[entryType + entry] = {mastery: nextMastery, nextReview: nextReview}
        return reviewUpdate
    }

    const buildNextReviewClean = (nextMastery, entryType, entry) => {
        const timeToNextReview = calcTimeToNextReview(nextMastery)
        const nextReview = timeToNextReview + Date.now()
        return {mastery: nextMastery, nextReview: nextReview, entry: entry, entryType: entryType}
    }

    const calcTimeToNextReview = (mastery) => {
        const hour = 1000 * 60 * 60
        return Math.pow(2, mastery) * hour
    }

    const answer = async (e) => {
        const answerIsCorrect = e.target.id === "true"
        const isLastEntry = entries.length === entryNumber + 1

        if (!answerIsCorrect) {
            const nextMastery = currentEntry.mastery ? currentEntry.mastery - 1 : 0
            addToQueueF(buildNextReview(nextMastery, currentEntry.entryType, currentEntry.entry), buildNextReviewClean(nextMastery, currentEntry.entryType, currentEntry.entry))
            setMadeAnError(true)
        } else if (answerIsCorrect && !isLastEntry && !madeAnError) {
            const nextMastery = currentEntry.mastery ? currentEntry.mastery + 1 : 0
            addToQueueF(buildNextReview(nextMastery, currentEntry.entryType, currentEntry.entry), buildNextReviewClean(nextMastery, currentEntry.entryType, currentEntry.entry))

            setEntryNumber(entryNumber + 1)
            setCurrentEntry(entries[entryNumber + 1])

        } else if (answerIsCorrect && !isLastEntry && madeAnError) {
            setMadeAnError(false)
            setEntryNumber(entryNumber + 1)
            setCurrentEntry(entries[entryNumber + 1])
        } else if (isLastEntry) {
            quizFinishedF()
        }
    }

    return !madeAnError ? <div>
            <h1 className="display-1 text-center">{currentEntry.entry}</h1>
            <div className="d-flex justify-content-center">
                <button className="btn btn-outline-success m-2 " id="true" onClick={answer}>Know it</button>
                <button className="btn btn-outline-danger m-2" id="false" onClick={answer}>Don't know it</button>
            </div>
            <div className="text-muted small text-center my-2" style={{cursor: "pointer"}} onClick={quizFinishedF}>Back to
                Summary
            </div>
        </div> :
        <div>
            <div className="d-flex justify-content-center">
                <WordFragment {...currentEntry}/>
                <button className="btn btn-outline-success m-2 " id="true" onClick={answer}>Continue</button>
            </div>

        </div>
}