import {Component, Fragment} from "react"
import {Badge, CardFooter} from "reactstrap"
import React from "react"

export class TokensFooter extends Component {
  state = {
    words: this.props.words ? this.props.words : [],
    kanji: this.props.kanji ? this.props.kanji : [],
    pinnedWords: this.props.pinnedWords ? this.props.pinnedWords : [],
    search: this.props.search ? this.props.search : "",
    selectedTab: this.props.selectedTab ? this.props.selectedTab : "default"
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.words !== prevProps.words || this.props.kanji !== prevProps.kanji || this.props.pinnedWords !== prevProps.pinnedWords) {
      this.setState({...this.props})
    }
  }

  tabClicked = async tab => {
    this.setState({selectedTab: tab})
  }
  wordsTabClicked = async => {
    this.tabClicked("words")
  }
  kanjiTabClicked = async => {
    this.tabClicked("kanji")
  }
  searchTabClicked = async => {
    this.tabClicked("search")
  }

  wordBadgeClicked = async event => {
    // const o = ((this.props.pinnedWords.length + 1) * 0.000001 + this.props.fragmentOrder)
    const o = (Date.now()*0.0000000000001 + this.props.fragmentOrder)
    this.props.saveWordFunction(event.target.innerHTML, this.props.fragmentId, o)
  }
  kanjiBadgeClicked = async event => {
    console.log(event.target.innerHTML)
  }

  render() {
    const words = this.state.words ? this.state.words.filter(w => w.includes(this.state.search) && !this.props.pinnedWords.includes(w)).map((w, i) =>
      <Badge key={i} style={{fontSize: "1.1em"}} className="mx-1" color="primary" onClick={this.wordBadgeClicked}
             pill>{w}</Badge>) : ""
    const kanji = this.state.kanji ? this.state.kanji.filter(w => w.includes(this.state.search) && !this.props.pinnedWords.includes(w)).map((w, i) =>
      <Badge key={i} style={{fontSize: "1.1em"}} className="mx-1" color="primary" onClick={this.kanjiBadgeClicked}
             pill>{w}</Badge>) : ""
    const footer = !(this.state.selectedTab === "none") && ((Array.isArray(this.state.words) && this.state.words.length) || (Array.isArray(this.state.kanji) && this.state.kanji.length)) ?
      <CardFooter className="pb-0 pl-0">
        {this.state.selectedTab === "words" || this.state.selectedTab === "default" ?
          <div>{words}&nbsp;</div> : this.state.selectedTab === "kanji" ? <div>{kanji}&nbsp;</div> :
            <div>&nbsp;</div>}
        <ul className="nav nav-tabs mt-2">
          {this.state.selectedTab === "words" || this.state.selectedTab === "default" ?
            <li className="nav-item nav-link active nonSelectable" onClick={this.wordsTabClicked}
                value="words">Words</li> :
            <li className="nav-item nav-link nonSelectable" onClick={this.wordsTabClicked} value="words">Words</li>}
          {this.state.selectedTab === "kanji" ?
            <li className="nav-item nav-link active nonSelectable" onClick={this.kanjiTabClicked}
                value="kanji">Kanji</li> :
            <li className="nav-item nav-link nonSelectable" onClick={this.kanjiTabClicked} value="kanji">Kanji</li>}

          <li><input type="text" className="form-control" placeholder="Search" id="search" value={this.state.search}
                     onChange={this.handleChange}
                     style={{outline: 0, borderWidth: 0, boxShadow: "none", borderBottom: "blue"}}/></li>
        </ul>
      </CardFooter>
      : ""
    return (
      <Fragment>
        {footer}
      </Fragment>
    )
  }
}