import {CardHeader, CardLink} from "reactstrap"
import React from "react"
// import Circle from "react-circle";

export const HeaderBar = (props) => {
  // const spinner = !props.isLoading ? <Circle size="18" progress={70} showPercentage={false} animated={true} animationDuration="3s" style={{webkitAnimation: "spin 4s infinite linear"}}/> : ""
  return <CardHeader className="px-2 py-2 border-primary">
    {/*{spinner}*/}
    {props.deleteClicked ? <CardLink className="float-right small card-link text-primary mx-2"
      onClick={props.deleteClicked}>Delete</CardLink> : null }
    { props.editClicked ? <CardLink className="float-right small card-link text-primary mx-2"
      onClick={props.editClicked}>Edit</CardLink> : null }
    {props.markWordKnown && !props.known ? <CardLink className="float-right small card-link text-primary mx-2" onClick={props.markWordKnown}>Know it</CardLink> : props.markWordKnown && props.known ?
      <CardLink className="float-right small card-link text-primary mx-2" onClick={props.markWordUnknown}>Don't know it</CardLink> : null }
    {props.addToListFunction && !props.isInList ? <CardLink className="float-right small card-link text-primary mx-2" onClick={props.addToListFunction}>Add to List</CardLink> : props.addToListFunction && props.isInList ?
        <CardLink className="float-right small card-link text-primary mx-2" onClick={props.removeFromListFunction}>Remove from List</CardLink> : null }
  </CardHeader>
}