import React, {Component} from "react"
import {FormGroup, Label, Input} from "reactstrap"
import {LoaderButton} from "../components/LoaderButton"
import {Link} from "react-router-dom"
import Confirm from "./Confirm"
import {UserContext} from "../UserContext"
import {API} from "aws-amplify";

export default class Login extends Component {

  state = {
    confirmationMode: false
  }

  render() {
    return (
      this.props.confirmationMode ? <Confirm/> : <LoginForm history={this.props.history}/>
    )
  }
}

class LoginForm extends Component {
  static contextType = UserContext

  state = {
    email: "",
    password: "",
    errorMsg: "",
    isLoading: false,
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleSubmit = async event => {
    event.preventDefault()
    this.setState({isLoading: true})

    this.context.login(this.state.email, this.state.password)
      .then(u => this.correctLogin(u))
      .catch(e => this.incorrectLogin(e))
  }

  correctLogin = (user) => {
    this.setState({isLoading: false})

    const userNotLinked = localStorage.getItem("userNotLinked") ? localStorage.getItem("userNotLinked") === "true" : false

    // Find out how login is redirecting automatically
    if (userNotLinked) {
      API.put("documentsGateway", "user/updateUserLink").then(r => {
        localStorage.setItem("userNotLinked", "false")
        this.props.history.push("/")
      })
    } else {
      this.props.history.push("/")
    }

  }

  incorrectLogin = (error) => {
    // console.log(error)
    this.setState({errorMsg: error.message, isLoading: false})
    if (error.code === "UserNotConfirmedException") {
      this.props.history.push({
        pathname: "/confirm",
        props: { tempPass: this.state.password, email: this.state.email }
      })
    }
  }

  validateForm() {
    return this.state.email.length > 3 && this.state.password.length > 4
  }

  render() {
    return (
      <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
        <h1>Sign in</h1>
        <form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for="email" className="form-control-label">Email</Label>
            <Input autoFocus type='email' value={this.state.email} onChange={this.handleChange} id="email"/>
          </FormGroup>
          <FormGroup>
            <Label className="form-control-label">Password</Label>
            <Input type="password" value={this.state.password} onChange={this.handleChange} id="password"/>
          </FormGroup>
          <p className="">
            <small><Link to="/forgotPassword">Forgot Password</Link></small>
          </p>
          {this.state.errorMsg ? <p className="text-danger">{this.state.errorMsg}</p> : null}
          <LoaderButton block disabled={!this.validateForm()} size="lg"
            type="submit"
            color="primary"
            text="Login"
            loadingText="Logging in..."
            isLoading={this.state.isLoading}/>
        </form>
      </div>
    )
  }
}