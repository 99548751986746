import React, {Component, Fragment} from "react"
import docI from "../images/documents.jpg"
import kanjiSearchI from "../images/kanji-search.jpg"
import wordSearchI from "../images/words-search.jpg"
import lookupWordsI from "../images/lookupwords.jpg"
import txtFragI from "../images/text-fragment.jpg"

export default class Home extends Component {
    componentDidMount() {
        document.title = "Home - Kanji App"
    }

    render() {
        return (
            <Fragment>

                <div className="container-fluid cover-container text-center d-flex flex-column">
                    <div className="row align-items-center justify-content-center flex-fill">
                        {/*style={{backgroundImage:"url('https://as2.ftcdn.net/jpg/02/28/48/03/500_F_228480319_OdrXgvIVZJyeiRyVm68P8L6R6YOE7GGL.jpg')", backgroundSize:"cover"}}*/}
                        <div>
                            <h1 className="display-4">Master the Japanese Language</h1><br/>
                            <p className="lead">An app to help learners read manga or books without having to stop every
                                two minutes to look up words.</p>

                            <div style={{maxWidth: "1400px"}} id="screenshots">
                                <h2 className="text-center my-2">Screenshots</h2>
                                <div className="card-deck m-3">
                                    <div className="card border-primary" style={{width: "18rem"}}>
                                        <img className="card-img-top" src={lookupWordsI}
                                             alt="Detect words & kanji in images or text"/>
                                        <div className="card-body bg-primary text-light">
                                            <p className="card-text lead">Detect words & kanji in images or text.</p>
                                        </div>
                                    </div>
                                    <div className="card border-primary" style={{width: "18rem"}}>
                                        <img className="card-img-top" src={txtFragI} alt="Automatically look up the definition of words & kanji that you
                                            don't know."/>
                                        <div className="card-body bg-primary text-light">
                                            <p className="card-text lead">Automatically look up the definition of words
                                                &
                                                kanji that you
                                                don't know.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-deck m-3">
                                    <div className="card border-primary" style={{width: "18rem"}}>
                                        <img className="card-img-top" src={docI}
                                             alt="Organize your learning resources in documents"/>
                                        <div className="card-body bg-primary text-light">
                                            <p className="card-text lead">Organize your learning resources in
                                                documents.</p>
                                        </div>
                                    </div>
                                    <div className="card border-primary" style={{width: "18rem"}}>
                                        <img className="card-img-top" src={kanjiSearchI} alt="Keep track of the kanji that you know or want to
                                            learn"/>
                                        <div className="card-body bg-primary text-light">
                                            <p className="card-text lead">Keep track of the kanji that you know or want
                                                to
                                                learn.</p>
                                        </div>
                                    </div>
                                    <div className="card border-primary" style={{width: "18rem"}}>
                                        <img className="card-img-top" src={wordSearchI} alt="Keep track of the words that you know or want to
                                            learn"/>
                                        <div className="card-body bg-primary text-light">
                                            <p className="card-text lead">Keep track of the words that you know or want
                                                to
                                                learn.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}