import React from 'react';
import spinner from '../../loading-spinner.svg'
import {Card} from "reactstrap"

export const LoadingFragment = (props) => {
    return (
        <Card style={{height:"10vh"}}>
                <img src={spinner} alt="loading" style={{maxHeight:"100%"}} className="img-fluid"/>
        </Card>
    );
}
