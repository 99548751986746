import React, {Component} from "react"
import { Input} from "reactstrap"
import {UserContext} from "../UserContext"
import {API} from "aws-amplify";

export default class NewDocument extends Component {

    static contextType = UserContext

    state = {
        title: "",
        isPublic: false
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    validateForm = async () => {
        return this.state.title.length > 0
    }
    setPrivate = async () => {
        this.setState({isPublic: false})
    }
    setPublic = async () => {
        this.setState({isPublic: true})
    }

    createList = async (item) => {
        return API.post("documentsGateway", "list/create", {
            body: item
        })
    }

    handleSubmit = async event => {
        event.preventDefault()
        const newDoc = {title: this.state.title, fragments: []}

        try {
            const createdDoc = await this.context.createDoc(newDoc)
            await this.createList({docSk: createdDoc.sk, docTitle: createdDoc.title})
            this.props.history.push("/documents")
        } catch (e) {
            alert(e)
            this.setState({isLoading: false})
        }
    }

    render() {
        return (
            <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
                <h2 className="bd-title">Create a new Document</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label for="title" className="form-control-label">Title</label>
                        <input
                            className="form-control"
                            type="text"
                            autoFocus
                            onChange={this.handleChange}
                            value={this.state.title}
                            id="title"
                        />
                    </div>
                    <div onChange={this.handleChange}>
                        <div className="form-check form-check-inline">
                            <Input type="radio" name="radioGroup1" id="radio1" checked={!this.state.isPublic}
                                   onClick={this.setPrivate}/>
                            <label className="form-check-label" for="radio1">Private</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input type="radio" name="radioGroup1" id="radio2" checked={this.state.isPublic}
                                   onClick={this.setPublic}/>
                            <label className="form-check-label" for="radio2">Public</label>
                        </div>
                    </div>
                    <button
                        className="btn btn-primary btn-block"
                        disabled={!this.validateForm()}
                        type="submit"
                    >Create</button>
                </form>
            </div>
        )
    }
}