import React, {Component} from "react"
import {FormGroup, Label, Input} from "reactstrap"
import {Auth} from "aws-amplify"
import {LoaderButton} from "../components/LoaderButton"
import Confirm from "./Confirm"
import {Link} from "react-router-dom"

export default class Signup extends Component {
  state = {
    confirmationMode: false,
    tempEmail: "",
    errorMsg: ""
  }

  afterSignUp = user => {
    // this.setState({isLoading: false})
    // console.log("after sign up user")
    // console.log(user)
    this.setState({confirmationMode: true, tempEmail: user})

    localStorage.setItem("tempEmail", user)
    this.props.history.push({
      pathname: "/confirm",
    })
  }

  switchMode = () => {
    this.setState({confirmationMode: !this.state.confirmationMode})
  }

  render() {
    return (
      this.state.confirmationMode ? <Confirm email={this.state.tempEmail}/> : <SignUpForm afterSignUp={this.afterSignUp}/>
    )
  }
}

export class SignUpForm extends Component{
  state = {
    isLoading: false,
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    newUser: null,
    errorMsg:  ""
  }


  validateForm = () => {
    return (
      this.state.email.length > 4 &&
      this.state.password.length > 8 &&
      this.state.password === this.state.confirmPassword
    )
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleSubmit = async event => {
    event.preventDefault()

    this.setState({isLoading: true})
    Auth.signUp({
      username: this.state.email,
      password: this.state.password
    }).then(u => this.afterSuccess(u))
      .catch(e => this.afterError(e))
    // this.setState({isLoading: false})
  }

  afterSuccess = u => {
    if (this.props.afterSignUp){
      // console.log("After success u is:")
      // console.log(u.user)
      // console.log(u.user.username)
      this.props.afterSignUp(u.user.username)
    }
  }

  afterError = e => {
    console.log(e)
    this.setState({errorMsg: e.message, isLoading: false})
    // Auth.completeNewPassword()
  }

  render(){
    return <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
      <h1>Create your account</h1>
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="email" className="form-control-label">Email</Label>
          <Input type='email' value={this.state.email} onChange={this.handleChange} id="email" autoFocus/>
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">Password</Label>
          <Input type="password" value={this.state.password} onChange={this.handleChange} id="password"/>
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">Confirm Password</Label>
          <Input type="password" value={this.state.confirmPassword} onChange={this.handleChange}
            id="confirmPassword"/>
        </FormGroup>
        <p><small>Already have an account? <Link to="/login">Sign in</Link></small></p>
        {this.state.errorMsg ? <p className="text-danger">{this.state.errorMsg}</p> : null}
        <LoaderButton color="primary" size="lg" block type="submit" text="Sign Up" loadingText="Signing up" isLoading={this.state.isLoading} />
      </form>
    </div>
  }
}
