import {Component, Fragment} from "react"
import {CardBody, CardFooter, CardLink} from "reactstrap"
import React from "react"

export class EditFragment extends Component {
  state = {
    content: this.props.content ? this.props.content : ""
  }
  contentChange = async event => {
    this.setState({
      content: event.target.value
    })
  }
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }
  shortcutPressed = async event => {
    if (event.ctrlKey && event.key === "Enter") {
      this.props.requestUpdate(this.state.content)
    }
  }
  keyDown = async event => {
    if (event.key === "Escape") {
      this.props.cancelClicked()
    }
  }
  updateClicked = async event => {
    this.props.requestUpdate(this.state.content)
  }

  render() {
    return (
      <Fragment>
        <CardBody>
          <textarea className="w-100" ref={input => input && input.focus()} value={this.state.content}
            onChange={this.contentChange} onKeyPress={this.shortcutPressed} onKeyDown={this.keyDown}/>
        </CardBody>
        <CardFooter>
          <CardLink className="text-primary" onClick={this.props.cancelClicked}>
            <small>Cancel</small>
          </CardLink>
          <CardLink className="pull-right btn btn-primary text-light"
            onClick={this.updateClicked}>Save <small>(Ctrl + Enter)</small></CardLink>
        </CardFooter>
      </Fragment>
    )
  }
}