import React from "react"
import {Switch} from "react-router-dom"
import AppliedRoute from "./components/AppliedRoute"
import AuthenticatedRoute from "./components/AuthenticatedRoute"
import UnauthenticatedRoute from "./components/UnauthenticatedRoute"

import Home from "./containers/Home"
import Login from "./containers/Login"
import Signup from "./containers/Singup"
import Confirm from "./containers/Confirm"
import Documents from "./containers/Documents"
import NewDocument from "./containers/NewDocument"
import Document from "./containers/Document"
import Words from "./containers/Words"
import Word from "./containers/Word"
import Kanji from "./containers/Kanji"
import Kanjis from "./containers/Kanjis"
import Forgot from "./containers/ForgotPassword"
import ResetPassword from "./containers/Reset"
import Lists from "./containers/Lists"
import List from "./containers/List"

export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps}/>
        <UnauthenticatedRoute path="/forgotPassword" exact component={Forgot} props={childProps}/>
        <UnauthenticatedRoute path="/reset" exact component={ResetPassword} props={childProps}/>
        <UnauthenticatedRoute path="/confirm" exact component={Confirm} props={childProps}/>
        <AuthenticatedRoute path="/documents" exact component={Documents} props={childProps}/>
        <AuthenticatedRoute path="/documents/new" exact component={NewDocument} props={childProps}/>
        <AppliedRoute path="/docs/:id" exact component={Document} props={childProps}/>
        <AuthenticatedRoute path="/words" exact component={Words} props={childProps}/>
        <AppliedRoute path="/words/:wordId" exact component={Word} props={childProps}/>
        <AuthenticatedRoute path="/kanji" exact component={Kanjis} props={childProps}/>
        <AppliedRoute path="/kanji/:kanjiId" exact component={Kanji} props={childProps}/>
        <AuthenticatedRoute path="/lists" exact component={Lists} props={childProps}/>
        <AuthenticatedRoute path="/lists/:id" exact component={List} props={childProps}/>
    </Switch>