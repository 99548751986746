import React from "react"

export const UserContext = React.createContext({
  user: {
    knownWords: ["context", "works"],
    knownKanji: [],
    documents: [{title: "hello there"}],
  },
  lists: [],
  documents: [],
  isAuthenticated: false,
  allKanji: [],
  refreshUser: () => {},
  refreshDocs: () => {},
  refreshLists: async () => {},
  deleteDoc: () => {},
  createDoc: () => {},
  login: () => {}
})