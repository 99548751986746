import React, {Component} from "react"
import {API} from "aws-amplify"
import {WordFragment} from "../components/Document/Fragments/WordFragment"

export default class Word extends Component {
    state = {
        word: null
    }
    readWord = async (word) => {
        return API.get("documentsGateway", "word/read", {"queryStringParameters": {"word": word}})
    }
    render() {
        const wordComponent= this.state.word ? <WordFragment {...this.state.word}/> : null
        return (
            <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
                {wordComponent}
            </div>
        )
    }
    async componentDidMount(){
        document.title = this.props.match.params.wordId + " - Kanji App"
        this.readWord(this.props.match.params.wordId).then(r => this.setState({word: r}))
    }
}