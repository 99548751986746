import React, {Component, useState} from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import {UserContext} from "../UserContext"
import {API, Storage} from "aws-amplify"
import {Map} from "immutable"

export default class Documents extends Component {

    static contextType = UserContext

    state = {
        itemsMap: Map({}),
        docs: this.context.documents
    }

    async componentDidMount() {
        document.title = "Documents - Kanji App"
        if (!this.props.isAuthenticated) return
        this.context.refreshDocs()
            .then(docs => this.setState({itemsMap: Map(docs.map(d => [d.sk, d])), docs: docs}))
    }

    handleDeleteInUI = async (sk) => {
        this.context.deleteDoc(sk)
        const newItemsMap = this.state.itemsMap.remove(sk)
        this.setState({itemsMap: newItemsMap, docs: newItemsMap.valueSeq()})
    }

    render() {
        const docs = this.state.docs.map((item, i) => <DocumentItem doc={item}
                                                                    deleteHandler={this.handleDeleteInUI}
                                                                    key={item.sk ? item.sk : i}/>)
        console.log("rendering")


        return (
            <div id="documents" className="mx-auto p-2" style={{maxWidth: "1280px"}}>
                <div id="title" className="d-flex justify-content-between mt-2 mb-3 mx-2">
                    <h2 className="bd-title">Documents</h2>
                    <Link to="/documents/new" className="btn btn-primary">New Document</Link>
                </div>
                <ul className="list-group mt-2 mx-2" id="document-list">
                    {docs}
                </ul>
            </div>
        )
    }
}

const DocumentItem = ({doc, deleteHandler}) => {
    const [editMode, setEditMode] = useState(false)
    const [title, setTitle] = useState(doc.title)

    const contentChange = async event => {
        setTitle(event.target.value)
    }

    const shortcutPressed = async event => {
        if (event.ctrlKey && event.key === "Enter") {
            // setEditMode(false)
            saveTitleChanges()
        }
    }

    const keyDown = async event => {
        if (event.key === "Escape") {
            setEditMode(false)
        }
    }

    const enterEditMode = async event => {
        setEditMode(true)
    }

    const exitEditMode = async event => {
        setEditMode(false)
    }

    const saveTitleChanges = async () => {
        setEditMode(false)
        saveToDB(title)
    }

    const saveToDB = async (title) => {
        return API.put("documentsGateway", "document/update", {
            body: {sk: doc.sk, title: title}
        })
    }

    return editMode === false ?
        <li className="list-group-item">
        <span style={{float: "left"}}>
            <Link to={`/docs/${doc.sk}`}>{title}</Link>
        </span>
            <span style={{float: "right"}}>
            <a className="text-danger" onClick={deleteHandler.bind(this, doc.sk)}>Delete</a>
        </span>
            <span className="mx-3" style={{float: "right"}}>
            <a className="text-danger" onClick={enterEditMode}>Rename</a>
        </span>
        </li>
        : <li className="list-group-item">
        <span style={{float: "left"}}>
            <input
                className="w-100"
                ref={input => input && input.focus()}
                value={title}
                onChange={contentChange}
                onKeyPress={shortcutPressed}
                onKeyDown={keyDown}
            />
            </span>
            <span style={{float: "right"}}>
            <a className="text-danger" onClick={exitEditMode}>Cancel</a>
        </span>
            <span className="mx-3" style={{float: "right"}}>
            <a className="text-danger" onClick={saveTitleChanges}>Save</a>
        </span>
        </li>
}

DocumentItem.propTypes = {
    doc: PropTypes.object.isRequired,
    deleteHandler: PropTypes.func.isRequired
}
