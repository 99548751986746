import React, {Component} from "react"

export default class Kanji extends Component {
    state = {
        kanji: null
    }
    componentDidMount() {
        document.title = this.props.match.params.kanjiId + " - Kanji App"
    }

    render() {
        return (
            <div className="mx-auto p-2" style={{maxWidth: "1280px"}}>
                <h1>{this.props.match.params.kanjiId}</h1>
            </div>
        )
    }
}